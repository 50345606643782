import http from '../../api/http';

export const REGISTER_TYPE = 'REGISTER_TYPE';
export const REGISTER_USER_CONTROL = 'REGISTER_USER_CONTROL';
export const REGISTER_USER_MAIL = 'REGISTER_USER_MAIL';
export const REGISTER_USER_PHONE = 'REGISTER_USER_PHONE';
export const REGISTER_GET_CITIES = 'REGISTER_GET_CITIES';
export const REGISTER_GET_DISTINCTS = 'REGISTER_GET_DISTINCTS';
export const REGISTER_GET_SCHOOLS = 'REGISTER_GET_SCHOOLS';
export const REGISTER_REGISTER = 'REGISTER_REGISTER';
export const PHONE_VERIFY = 'PHONE_VERIFY';
export const NEW_PHONE_VERIFY = 'NEW_PHONE_VERIFY';
export const CAPTCHA_CODE = 'CAPTCHA_CODE';
export const CAPTCHA_CAP = 'CAPTCHA_CAP';
export const SET_PHONE_NUMBER = 'SET_PHONE_NUMBER';
export const TIME_LEFT = 'TIME_LEFT';
export const REGISTER_USER = 'REGISTER_USER';
export const REGISTER_USER_CORPORATE = 'REGISTER_USER_CORPORATE';
export const REGISTER_INFO = 'REGISTER_INFO';
export const REGISTER_SUBMIT = 'REGISTER_SUBMIT';


export const FORGOT_CHECK = 'FORGOT_CHECK';
export const FORGOT_CHECK_CONTROL = 'FORGOT_CHECK_CONTROL';
export const FORGOT_SUBMIT_PASSWORD = 'FORGOT_SUBMIT_PASSWORD';
export const FORGOT_RECHECK = 'FORGOT_RECHECK';

export const GET_COMPANY_CLASSES = 'GET_COMPANY_CLASSES';
export const GET_CLASSES_BRANCH = 'GET_CLASSES_BRANCH';

const state = {
  userControl: null,
  userMail: null,
  userPhone: null,
  registerUser: null,
  registerSubmit: null,
  userInfo: [],
  registerType: [],
  cities: [],
  distincts: [],
  schools: [],
  registerDone: '',
  phoneVerify: '',
  newPhoneVerify: '',
  captchaSms: '',
  phoneNumber: '',
  captchaEncrypted: '',
  forgotCheck: null,
  forgotControl: null,
  forgotSubmit: null,
  forgotReChek: null,
  companyClasses: [],
  classesBranch: [],
};

const getters = {
  forgotCheck: (state) => state.forgotCheck,
  forgotControl: (state) => state.forgotControl,
  forgotSubmit: (state) => state.forgotSubmit,
  forgotReChek: (state) => state.forgotReChek,
  userControl: (state) => state.userControl,
  userMail: (state) => state.userMail,
  userPhone: (state) => state.userPhone,
  userInfo: (state) => state.userInfo,
  registerUser: (state) => state.registerUser,
  registerSubmit: (state) => state.registerSubmit,
  registerType: (state) => state.registerType,
  cities: (state) => state.cities,
  distincts: (state) => state.distincts,
  schools: (state) => state.schools,
  registerDone: (state) => state.registerDone,
  phoneVerify: (state) => state.phoneVerify,
  newPhoneVerify: (state) => state.newPhoneVerify,
  captchaSms: (state) => state.captchaSms,
  captchaEncrypted: (state) => state.captchaEncrypted,
  phoneNumber: (state) => state.phoneNumber,
  companyClasses: (state) => state.companyClasses,
  classesBranch: (state) => state.classesBranch,
};

const actions = {
  [GET_COMPANY_CLASSES]: async ({ commit }, payload) => {
    try {
      const r = await http.get('/Kayit/Kayit/SubeGetir?sirketId=' + payload);
      const data = r.data;
      data.sort((a, b) => a.subeAd.localeCompare(b.subeAd));
      commit(GET_COMPANY_CLASSES, data);
    } catch (error) {
      commit(GET_COMPANY_CLASSES, error.response.data);
    }
  },
  [GET_CLASSES_BRANCH]: async ({ commit }, payload) => {
    try {
      const r = await http.get('/Kayit/Kayit/SinifGetir?subeId=' + payload);
      commit(GET_CLASSES_BRANCH, r.data);
    } catch (err) {
      commit(GET_CLASSES_BRANCH, err.response.data);
    }
  },
  [REGISTER_USER_CONTROL]: async ({ commit }, payload) => {
    try {
      await http.get(
        '/Kayit/Kullanici/KullaniciAdiVarMi?kullaniciAdi=' + payload
      );
      commit(REGISTER_USER_CONTROL, null);
    } catch (error) {
      commit(REGISTER_USER_CONTROL, error.response.data);
    }
  },
  [REGISTER_USER_MAIL]: async ({ commit }, payload) => {
    try {
      await http.get('/Kayit/Kullanici/EpostaVarMi?eposta=' + payload);
      commit(REGISTER_USER_MAIL, null);
    } catch (error) {
      commit(REGISTER_USER_MAIL, error.response.data);
    }
  },
  [REGISTER_USER_PHONE]: ({ commit }, payload) => {
    return http
      .get('/Kayit/Kullanici/TelefonVarMi?telefon=' + payload)
      .then(() => {
        commit(REGISTER_USER_PHONE, null);
      })
      .catch((error) => {
        commit(REGISTER_USER_PHONE, error.response.data);
      });
  },
  [REGISTER_USER_CORPORATE]: ({ commit }, payload) => {
    return http
      .post('/Kayit/Kayit/KurumsalKayitOl', payload)
      .then((response) => {
        commit('REGISTER_USER', {
          data: response.data,
          status: response.status,
        });
      })
      .catch((error) => {
        commit('REGISTER_USER', {
          data: error.response.data,
          status: error.response.status,
        });
      });
  },
  [REGISTER_USER]: ({ commit }, payload) => {
    return http
      .post('/Kayit/Kayit/KayitOl', payload)
      .then((response) => {
        commit('REGISTER_USER', {
          data: response.data,
          status: response.status,
        });
      })
      .catch((error) => {
        commit('REGISTER_USER', {
          data: error.response.data,
          status: error.response.status,
        });
      });
  },
  [REGISTER_INFO]: ({ commit }, payload) => {
    return http
      .get('/Kayit/Kullanici/MongoIdyeGoreOgrenciGetir?mongoId=' + payload)
      .then((response) => {
        commit(REGISTER_INFO, response.data);
      })
      .catch((error) => {
        commit(REGISTER_INFO, error.response.data);
      });
  },
  [REGISTER_SUBMIT]: ({ commit }, payload) => {
    return http
      .post(
        `/Kayit/Kayit/KullaniciOnayKodu?mongoId=${payload.mongoId}&onayKodu=${payload.onayKodu}`
      )
      .then((response) => {
        commit(REGISTER_SUBMIT, {
          data: response.data,
          status: response.status,
        });
      })
      .catch((error) => {
        commit(REGISTER_SUBMIT, {
          data: error.response.data,
          status: error.response.status,
        });
      });
    // /Kayit/Kayit/KullaniciOnayKodu
  },
  // İller
  [REGISTER_GET_CITIES]: ({ commit }) => {
    return http.get('/Kayit/Adres/SehirListele').then((response) => {
      commit(REGISTER_GET_CITIES, response.data);
    });
  },
  // İlçeler
  [REGISTER_GET_DISTINCTS]: ({ commit }, cityId) => {
    http.get('/Kayit/Adres/IlceListele?sehirId=' + cityId).then((response) => {
      commit(REGISTER_GET_DISTINCTS, response.data);
    });
  },
  // Okullar
  [REGISTER_GET_SCHOOLS]: ({ commit }, distinctsId) => {
    http
      .get('/Kayit/Adres/OkulListele?IlceId=' + distinctsId)
      .then((response) => {
        commit(REGISTER_GET_SCHOOLS, response.data);
      });
  },
  // Captcha Code
  [CAPTCHA_CODE]: ({ commit }) => {
    http
      .get('/Kayit/Kayit/CaptchaImage')
      .then((response) => {
        commit(CAPTCHA_CODE, response.data.img.fileContents);
        commit(CAPTCHA_CAP, response.data.cap);
      })
      .catch((err) => {
        console.error(err.response);
      });
  },

  [FORGOT_CHECK]: ({ commit }, payload) => {
    return http
      .get(
        `/Kayit/Kayit/KullaniciAdiveTelefonIleKontrol?kullaniciAdi=${payload.kullaniciAdi}&cepTelefonu=${payload.cepTelefonu}`
      )
      .then((response) => {
        commit(FORGOT_CHECK, {
          data: response.data,
          status: response.status,
        });
      })
      .catch((error) => {
        commit(FORGOT_CHECK, {
          data: error.response.data,
          status: error.response.status,
        });
      });
  },
  [FORGOT_CHECK_CONTROL]: ({ commit }, payload) => {
    return http
      .get(
        `/Kayit/Kayit/OnayKoduKontrol?mongoId=${payload.mongoId}&onayKodu=${payload.onayKodu}`
      )
      .then((response) => {
        commit(FORGOT_CHECK_CONTROL, {
          data: response.data,
          status: response.status,
        });
      })
      .catch((error) => {
        commit(FORGOT_CHECK_CONTROL, {
          data: error.response.data,
          status: error.response.status,
        });
      });
  },
  [FORGOT_SUBMIT_PASSWORD]: ({ commit }, payload) => {
    return http
      .get(
        `/Kayit/Kayit/SifreGuncelle?mongoId=${payload.mongoId}&sifre=${payload.sifre}&sifreTekrar=${payload.sifreTekrar}`
      )
      .then((response) => {
        commit(FORGOT_SUBMIT_PASSWORD, {
          data: response.data,
          status: response.status,
        });
      })
      .catch((error) => {
        commit(FORGOT_SUBMIT_PASSWORD, {
          data: error.response.data,
          status: error.response.status,
        });
      });
  },
  [FORGOT_RECHECK]: ({ commit }, payload) => {
    return http
      .get(`/Kayit/Kayit/TekrarSmsOnayKodu?mongoId=${payload.mongoId}`)
      .then((response) => {
        commit(FORGOT_RECHECK, {
          data: response.data,
          status: response.status,
        });
      })
      .catch((error) => {
        commit(FORGOT_RECHECK, {
          data: error.response.data,
          status: error.response.status,
        });
      });
  },
};

const mutations = {
  [GET_COMPANY_CLASSES]: (state, companyClasses) => {
    state.companyClasses = companyClasses;
  },
  [GET_CLASSES_BRANCH]: (state, classesBranch) => {
    state.classesBranch = classesBranch;
  },
  [FORGOT_RECHECK]: (state, forgotReChek) => {
    state.forgotReChek = forgotReChek;
  },
  [FORGOT_SUBMIT_PASSWORD]: (state, forgotSubmit) => {
    state.forgotSubmit = forgotSubmit;
  },
  [FORGOT_CHECK_CONTROL]: (state, forgotControl) => {
    state.forgotControl = forgotControl;
  },
  [FORGOT_CHECK]: (state, forgotCheck) => {
    state.forgotCheck = forgotCheck;
  },
  [REGISTER_USER_CONTROL]: (state, user) => {
    state.userControl = user;
  },
  [REGISTER_USER_MAIL]: (state, mail) => {
    state.userMail = mail;
  },
  [REGISTER_USER_PHONE]: (state, phone) => {
    state.userPhone = phone;
  },
  [REGISTER_INFO]: (state, userInfo) => {
    state.userInfo = userInfo;
  },
  [REGISTER_USER]: (state, registerUser) => {
    state.registerUser = registerUser;
  },
  [REGISTER_SUBMIT]: (state, registerSubmit) => {
    state.registerSubmit = registerSubmit;
  },
  [REGISTER_GET_CITIES]: (state, data) => {
    state.cities = data;
  },
  [REGISTER_GET_DISTINCTS]: (state, data) => {
    state.distincts = data;
  },
  [REGISTER_GET_SCHOOLS]: (state, data) => {
    state.schools = data;
  },
  [REGISTER_REGISTER]: (state, data) => {
    state.registerDone = data;
  },
  [CAPTCHA_CODE]: (state, data) => {
    state.captchaSms = data;
  },
  [CAPTCHA_CAP]: (state, cap) => {
    state.captchaEncrypted = cap;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
